import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import BackgroundImage from "gatsby-background-image";

const variants = {
  blur: {
    y: 190,
    backdropFilter: "blur(0)",
  },
  focus: {
    y: 0,
    backdropFilter: "blur(10px)",
  },
};

const CategoryCard = ({ to, image, title, description, cta }) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <Link style={{ display: "inline-block" }} to={to}>
      <Card
        fixed={image}
        critical={true}
        onTouchStart={handleFocus}
        onTouchEnd={handleBlur}
        onMouseOver={handleFocus}
        onFocus={handleFocus}
        onMouseLeave={handleBlur}
        onBlur={handleBlur}
      >
        <CardOverlay
          initial="blur"
          animate={isFocused ? "focus" : "blur"}
          variants={variants}
          transition={{ ease: "easeIn" }}
        >
          <h2>{title}</h2>
          <p>{description}</p>
          {cta && (
            <CardButton whileHover={{ scale: 1.08 }}>Learn More</CardButton>
          )}
        </CardOverlay>
      </Card>
    </Link>
  );
};

export default CategoryCard;

const Card = styled(BackgroundImage)`
  --primary-color: white;
  color: var(--primary-color);
  position: relative;
  width: 300px;
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  text-decoration: none;
  overflow: hidden;
  box-shadow: var(--shadow);
`;

const CardOverlay = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr 3fr;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  padding: 1em;
  text-align: center;

  h2 {
    align-self: center;
    font-size: 1.5rem;
  }
`;

const CardButton = styled(motion.div)`
  background: rgba(255, 255, 255, 0.35);
  border: solid 1px rgba(255, 255, 255, 0.5);
  color: white;
  padding: 0.5em;
  border-radius: 0.25rem;
`;
