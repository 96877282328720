import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Title from "../components/Title";
import Card from "../components/Card";
import SEO from "../components/SEO";

export const query = graphql`
  query CategoryQuery {
    allSanityCategory(sort: { fields: order }) {
      edges {
        node {
          id
          title
          description
          image {
            asset {
              fixed(height: 300, width: 300) {
                ...GatsbySanityImageFixed
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;

function Services({ data }) {
  const { edges } = data.allSanityCategory;
  return (
    <>
      <SEO
        title="CLIPS Hair Studio | Services"
        description="We offer a wide range of services including hair cuts, color, keratin treatments, skincare and more!"
        url="https://www.clips-hairstudio.com/services/"
      />
      <ServiceContent>
        <Title>Services</Title>
        <Grid>
          {edges.map(({ node }) => (
            <Card
              to={`/services/${node.slug.current}/`}
              key={node.id}
              image={node.image.asset.fixed}
              title={node.title}
              description={node.description}
              cta="Learn More"
            />
          ))}
        </Grid>
      </ServiceContent>
    </>
  );
}

export default Services;

const ServiceContent = styled.div`
  position: relative;
  top: var(--nav-height);
  padding-bottom: 2rem;
  min-height: calc(100vh - var(--nav-height));
`;

const Grid = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;
  grid-gap: 2rem;
`;
